import { default as indexFCBv1Fu6ayMeta } from "/opt/build/repo/pages/activity/[parent]/[slug]/index.vue?macro=true";
import { default as indexCvqKFUPAkdMeta } from "/opt/build/repo/pages/activity/[parent]/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as newsletter4iem4JeVdNMeta } from "/opt/build/repo/pages/newsletter.vue?macro=true";
import { default as component_45stubKV3LCdReTqMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKV3LCdReTq } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "activity-parent-slug___it",
    path: "/:parent()/:slug()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "activity-parent___it",
    path: "/:parent()",
    component: () => import("/opt/build/repo/pages/activity/[parent]/index.vue").then(m => m.default || m)
  },
  {
    name: "index___it",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "newsletter___it",
    path: "/newsletter",
    component: () => import("/opt/build/repo/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: component_45stubKV3LCdReTqMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubKV3LCdReTq
  }
]
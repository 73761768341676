import revive_payload_client_ALzNfNuR8r from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6viCcdo77S from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_miPi750kSw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_rWqmVVRnPS from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.17_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@20.16.5_sass@1.78_kr7ztibtperth2xhv555d6tf6e/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_8W0WTDU3pd from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_j7ANr8N2pn from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Fb64ETSftW from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_nQmLfuCKtD from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Vjyn3HDc4y from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_rollup@4.21.2_typescript@5.5.3_vue@3.5.4_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_qHLAjuJgFw from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.16.5_eslint@8.57.0_ioredis@5.4.1_magicast@0._7u6h3bsrxtwcjfl4tvlv472aji/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_UFIapi6nmO from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node_lum4g53ywznfj4novukobpun34/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt_plugin_32B1TYVd89 from "/opt/build/repo/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_YJHLc47DU8 from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.21.2_vite@5.4._kvk57umanfoonnxyukpwtbfv5y/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_Tsxub1HC41 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_magicast@0.3.5_rollup@4.21.2_typescript@5.5.3_vue@3.5.4_typescri_srhwdzexqdrko5opinfk54uqre/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_wYPytgLgwP from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_yhK2RpKU3W from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_magicast@0.3.5_rollup@4.21.2_vue@3.5.4_typescript@5.5.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import directives_8CcCirWtnE from "/opt/build/repo/plugins/directives.ts";
export default [
  revive_payload_client_ALzNfNuR8r,
  unhead_6viCcdo77S,
  router_miPi750kSw,
  _0_siteConfig_rWqmVVRnPS,
  payload_client_8W0WTDU3pd,
  navigation_repaint_client_j7ANr8N2pn,
  check_outdated_build_client_Fb64ETSftW,
  chunk_reload_client_nQmLfuCKtD,
  plugin_vue3_Vjyn3HDc4y,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qHLAjuJgFw,
  plugin_UFIapi6nmO,
  nuxt_plugin_32B1TYVd89,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_YJHLc47DU8,
  plugin_Tsxub1HC41,
  switch_locale_path_ssr_wYPytgLgwP,
  i18n_yhK2RpKU3W,
  apollo_IRWTEOPleP,
  directives_8CcCirWtnE
]